<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  watch: {
    $route(to) {
      document.title = to.meta.title;
    }
  },
  created() {
    // 判断是手机端还是PC端
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.$router.push({
        path: '/mobileHome',
      });
    } else {
      this.$router.push({
        path: '/',
      });
    }
  }
}
</script>

<style>
@import url("./assets/common.css");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
