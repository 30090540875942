import { createRouter, createWebHistory } from 'vue-router';
/**
 * navTabbar 是否显示顶部导航栏 true显示 false不显示
 * keepAlive true 页面 缓存 false  不缓存
 */
const routes = [{
    path: '/',
    name: 'home',
    meta: { title: '有任务' },
    component: () =>
        import ( /* webpackChunkName: "home" */ '@/views/home/index.vue')
}, {
    path: '/mobileHome',
    name: 'mobileHome',
    meta: { title: '有任务' },
    component: () =>
        import ( /* webpackChunkName: "mobileHome" */ '@/views/mobileHome/index.vue')
}]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export function setupRouter(app) {
    app.use(router);
    // 创建路由守卫
}
export default router;